import { useTranslate } from '/machinery/I18n'
import { VisuallyHidden } from '/features/a11y/VisuallyHidden'

import styles from './CountWithLabel.css'

export function CountWithLabel({ count, total, totalFilteredText, noFilteredText }) {
  const { __ } = useTranslate()

  return (
    <>
      <VisuallyHidden>
        <span aria-atomic='false' aria-relevant="text" aria-live="polite" role="status" >
          {total < count
            ? `${total} ${__`of-the`} ${count} ${totalFilteredText}`
            : `${total} ${noFilteredText}`
          }
        </span>
      </VisuallyHidden>

      {total < count
        ? <MatchSome {...{ total, count, totalFilteredText }} />
        : <MatchAll {...{ total, noFilteredText }} />
      }
    </>
  )
}

function MatchSome({ total, count, totalFilteredText }) {
  const { __ } = useTranslate()
  return (
    <>
      <span className={styles.highlight}>{total}</span>
      &nbsp;
      {__`of-the`}
      &nbsp;
      <span className={styles.highlight}>{count}</span>
      &nbsp;
      {totalFilteredText}
    </>
  )
}

function MatchAll({ total, noFilteredText }) {
  return (
    <>
      <span className={styles.highlight}>{total}</span>
      &nbsp;
      {noFilteredText}
    </>
  )
}
