import { getPagination } from '@kaliber/pagination'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Pagination.css'

import chevronLeftIcon from '/images/icons/chevron-left.raw.svg'
import doubleChevronLeft from '/images/icons/double-chevron-left.raw.svg'
import chevronRightIcon from '/images/icons/chevron-right.raw.svg'
import doubleChevronRight from '/images/icons/double-chevron-right.raw.svg'

export function Pagination({ currentPage, maxPages, onPageChange }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { __ } = useTranslate()

  const pagination = getPagination({
    current: currentPage,
    max: maxPages,
    padding: isViewportMd ? 2 : 0
  })

  return (
    <nav className={styles.component}>
      <ArrowButton
        dataX='click-to-first-page'
        disabled={currentPage === 1}
        layoutClassName={styles.previousLayout}
        onClick={() => handleClick(1)}
        ariaLabel={__`pagination-first-page`}
        icon={doubleChevronLeft}
      />
      <ArrowButton
        dataX='click-to-previous-page'
        disabled={currentPage === 1}
        layoutClassName={styles.previousLayout}
        onClick={() => handleClick(currentPage - 1)}
        ariaLabel={__`pagination-previous-page`}
        icon={chevronLeftIcon}
      />

      {pagination.map((x, i) => x
        ? (
          <Bullet
            onClick={() => handleClick(x)}
            layoutClassName={styles.bulletLayout}
            active={currentPage === x}
            key={`${x}-${i}`}
            number={x}
          />)
        : <span className={styles.bulletSeparator} key={`${x}-${i}`}>…</span>
      )}

      <ArrowButton
        dataX='click-to-next-page'
        onClick={() => handleClick(currentPage + 1)}
        ariaLabel={__`pagination-next-page`}
        icon={chevronRightIcon}
        disabled={currentPage === maxPages}
        layoutClassName={styles.nextLayout}
      />
      <ArrowButton
        dataX='click-to-next-page'
        onClick={() => handleClick(maxPages)}
        ariaLabel={__`pagination-last-page`}
        icon={doubleChevronRight}
        disabled={currentPage === maxPages}
        layoutClassName={styles.nextLayout}
      />
    </nav>
  )

  function handleClick(page) {
    onPageChange(page)
  }
}

function ArrowButton({ onClick, dataX, icon, disabled, ariaLabel, layoutClassName }) {
  return (
    <button
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.componentArrowButton, disabled && styles.disabled, layoutClassName)}
      {...{ onClick, disabled }}
    >
      <Icon {... { icon }} />
    </button>
  )
}

function Bullet({ number, active, layoutClassName, onClick }) {
  const { __ } = useTranslate()
  return (
    <button
      data-style-context={active && 'blue'}
      data-x={`click-to-${number}-page`}
      aria-label={__({ x: number })`pagination-x-count`}
      type='button'
      className={cx(styles.componentBullet, layoutClassName)}
      {...{ onClick }}
    >
      {number}
    </button>
  )
}
